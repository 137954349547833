<template>
 <div>
   <v-autocomplete
       v-model="filter"
       :items="creators"
       :search-input.sync="searchCreator"
       clearable
       outlined
       color="accent"
       hide-no-data
       hide-selected
       item-text="username"
       item-value="username"
       placeholder="Einem Anwalt zuweisen"
       return-object
       @change="addLawyer()"
   ></v-autocomplete>
   <h2 disabled v-if="showOtherCaseMessage"> {{ showOtherCaseMessage }} </h2>
 </div>
</template>

<script>
import {sessionHandler} from "@/request/sessionHandler";
import {getLawyersURL, requestMode} from "@/configBuilder";
import {showAlert} from "@/utils/alertHandler";
import i18n from "@/plugins/i18n";

export default {
  name: "RebindCase",
  props: {
    id: {
      type: Number,
      required: true
    },
    controllerURL: {
      required: true
    },
    unassignedCase: {
      required: true
    },
    rebindPermission: {
      required: true
    },
    dataTypeName: {
      required: true
    },
  },
  data: () => ({
    filter: undefined,
    searchCreator: null,
    showOtherCaseMessage: null,
    creators: [],
    linkedCases: [],
  }),
  methods: {
    test() {
      console.log(this.dataTypeName)
    },
    async getAllCases(){
      this.isLoading = true;
      let parameter = {
        'caseUUID': this.unassignedCase.caseUUID
      }
      await sessionHandler();
      await this.$http
          .get(
              this.controllerURL
              , {
                mode: requestMode(),
                params: parameter,
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then((response) => {
            if (response.status === 200) {
              this.linkedCases = response.data.filter((linkedCase) => {
                return linkedCase[this.dataTypeName] === "Coaching" || linkedCase[this.dataTypeName] === "Dokumentencheck"
              })
              if (this.linkedCases.length === 2) {
                this.showOtherCaseMessage = "Beim Zuweisen dieses Falls wird ebenfalls ein " + (this.rebindPermission === "Coaching" ? "Dokumentencheck" : "Coaching") + " Fall zugewiesen!"
              }
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "Calendar-gE2" + this.admin, "error");
            }
          })
          .finally(() => {
            this.isLoading = false;
          })
    },
    async getAllCreators() {
      // look if Session isValid
      await sessionHandler();
      this.$http
          .get(
              getLawyersURL()
              , {
                mode: requestMode(),
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then((response) => {
            if (response.status === 200) {
              if (this.rebindPermission === "Dokumentencheck" || this.rebindPermission === "Coaching"){
                if (this.linkedCases.length === 2){
                  let tempCreators = response.data.filter((creator) => { return creator[this.dataTypeName.substring(0, this.dataTypeName.length-8)  + "Permissions"][this.rebindPermission]})
                  tempCreators = tempCreators.filter((creator) => { return creator[this.dataTypeName.substring(0, this.dataTypeName.length-8)  + "Permissions"][this.rebindPermission === "Dokumentencheck" ? "Coaching" : "Dokumentencheck"]})
                  this.creators = tempCreators;
                } else {
                  this.creators = response.data.filter((creator) => { return creator[this.dataTypeName.substring(0, this.dataTypeName.length-8)  + "Permissions"][this.rebindPermission]})
                }
              } else {
                this.creators = response.data.filter((creator) => { return creator[this.dataTypeName.substring(0, this.dataTypeName.length-8)  + "Permissions"][this.rebindPermission]})
              }
              // console.log(this.dataTypeName.substring(0, this.dataTypeName.length-8))
              if (this.creators.length === 0) {
                showAlert(i18n.t('info.noEntries'), "info");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "Calendar-gAC1", "error");
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "Calendar-gAC2", "error");
            }
          })
          .finally(() => {
            this.isLoadingCreator = false;
          })
    },
    async addLawyer() {
          await sessionHandler();
          await this.$http
              .patch(
                  this.controllerURL + "/" + this.id,
                  {
                    "createdBy": this.filter.username
                  },
                  {
                    mode: requestMode(),
                    headers: {
                      'Accept': 'application/json',
                      'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                    },
                  }
              )
              .then(() => {
                this.$emit("getCases")
              });
          if (this.rebindPermission === "Dokumentencheck" || this.rebindPermission === "Coaching"){
            if (this.linkedCases.length === 2){
              const otherCaseToRebindType = this.rebindPermission === "Dokumentencheck" ? "Coaching" : "Dokumentencheck"
              const otherCaseToRebind = this.linkedCases.filter((linkedCase) => { return linkedCase[this.dataTypeName] === otherCaseToRebindType})[0]
              await sessionHandler();
              await this.$http
                  .patch(
                      this.controllerURL + "/" + otherCaseToRebind.id,
                      {
                        "createdBy": this.filter.username
                      },
                      {
                        mode: requestMode(),
                        headers: {
                          'Accept': 'application/json',
                          'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                        },
                      }
                  )
                  .then(() => {
                    this.$emit("getCases")
                  });
            }
          }
    },
  },
  computed: {},
  watch: {
    searchCreator() {
      // Items have already been loaded
      if (this.creators.length > 0) return

      // Items have already been requested
      if (this.isLoadingCreator) return

      this.isLoadingCreator = true

      // Lazily load input items
      this.getAllCreators();

    },
  },
  mounted() {
    this.getAllCases();
  },
}
</script>

<style scoped>

</style>
