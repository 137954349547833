<template>
  <v-card>
    <v-card-title>
      {{ closedCase.customerName }}
      {{ closedCase.customerLastname }}
      <v-spacer></v-spacer>
      <customer-mail-dialog v-bind:controllerURL="controllerURL" v-bind:currentCase="closedCase" v-on:getCases="getCases()"/>
      <v-btn :href="'tel:' + closedCase.customerPhoneNumber" color="primary" icon>
        <v-icon>mdi-phone</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-card color="primary" flat>
        <v-card-title>
          Fragestellung: <view-more v-bind:text="closedCase.notes" v-bind:cutting-count="200" />
        </v-card-title>
        <v-card-subtitle>
          <span v-if="closedCase.customerMailAddress">{{ closedCase.customerMailAddress }}</span><br v-if="closedCase.customerMailAddress" />
          <span v-if="closedCase.customerPhoneNumber">{{ closedCase.customerPhoneNumber }}</span><br v-if="closedCase.customerPhoneNumber" />
          <span v-if="closedCase.claimNumber">Schadennummer: {{ closedCase.claimNumber }}</span><br v-if="closedCase.claimNumber" />
          <span v-if="closedCase.contractNumber" >Versicherungsnummer:  {{ closedCase.contractNumber }}</span>
        </v-card-subtitle>
        <div v-if="closedCase.comment">
          <v-card-subtitle>Kommentar</v-card-subtitle>
          <v-card-text>
            <view-more v-bind:text="closedCase.comment" />
          </v-card-text>
        </div>
        <div v-if="closedCase.record">
          <v-card-subtitle>Sachverhalt</v-card-subtitle>
          <v-card-text>
            <view-more v-bind:text="closedCase.record" />
          </v-card-text>
        </div>
      </v-card>
    </v-card-text>
    <v-card-text>
      <v-card flat color="primary">
        <v-card-title>
          Erstellt am {{ closedCase.createdAt }} von {{ closedCase.createdBy }}
        </v-card-title>
      </v-card>
    </v-card-text>
    <v-card-text v-if="closedCase.chevalierTransmitted" >
      <v-card flat color="primary">
        <v-card-title>
          Dieser Fall wurde an Chevalier übermittelt
        </v-card-title>
      </v-card>
    </v-card-text>
    <v-card-text>
      <v-card flat color="error">
        <v-card-title>
          Geschlossen am {{ closedCase.updatedAt }} {{ closedCase.updatedBy === closedCase.createdBy ? "" : " von " + closedCase.updatedBy }}
        </v-card-title>
        <v-card-subtitle v-if="checkIfSpAvailable()">Wurde zu einer Servicepauschale umgewandelt</v-card-subtitle>
      </v-card>
    </v-card-text>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-textarea v-model="closedCase.closingReport" disabled color="primary" label="Sachverhaltsbeschreibung" outlined></v-textarea>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {sessionHandler} from "@/request/sessionHandler";
import {getAragURL, requestMode} from "@/configBuilder";
import {showAlert} from "@/utils/alertHandler";
import i18n from "@/plugins/i18n";
import reactiveStorage from "@/plugins/reactiveStorage";
import CustomerMailDialog from "@/components/Case/CustomerMailDialog";
import ViewMore from "@/components/ViewMore";

export default {
  name: "ClosedCaseCard",
  props: {
    closedCase: {
      required: true
    },
    controllerURL: {
      required: true
    },
  },
  data: () => ({
    admin: reactiveStorage.user.roles.Admin,
    description: document.description,
    dialog: false,
    closingCustomerMessage: "",
  }),
  components: {ViewMore, CustomerMailDialog },
  methods: {
    checkIfSpAvailable() {
      if (this.closedCase.aragDataType){
        return this.closedCase.lawyerCouldNotHelpCustomer
      }
      return false
    },
    async close(documentId) {
      await sessionHandler();
      await this.$http
          .patch(
              this.controllerURL + "/" + documentId,
              {
                "closingReport": this.document.closingReport,
                "closed": !this.document.closed
              }
              , {
                mode: requestMode(),
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then((response) => {
            if (response.status === 200) {
              this.getDocuments();
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "Calendar-gE2" + this.admin, "error");
            }
          })
          .finally(() => {
            this.isLoadingBill = false;
          })
    },
    updateCases() {
      this.$emit("getCases")
    },
    async sendMail(documentId) {
      await sessionHandler();
      await this.$http
          .patch(
              getAragURL(true) + "/" + documentId,
              {
                "closingCustomerMessage": this.closingCustomerMessage,
              }
              , {
                mode: requestMode(),
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then((response) => {
            if (response.status === 200) {
              this.updateCases();
              showAlert("Die E-Mail wurde erfolgreich versendet", "success");
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "CloseCaseCard-sendMail-1", "error");
            }
          })
          .finally(() => {
            this.dialog = false
          })
    },
  },
  computed: {}
}
</script>

<style scoped>

</style>
