<template>
  <div>
    <v-toolbar class="text-h5 primary">Verwandte Fälle <v-spacer></v-spacer>
      <v-btn v-if="linkedCases.length > 0" :href="'tel:' + linkedCases[0].customerPhoneNumber" color="white" icon>
        <v-icon>mdi-phone</v-icon>
      </v-btn>
    </v-toolbar>
    <div  v-if="linkedCases.length > 0">
      <v-card-title>
        {{ linkedCases[0].customerName }}
        {{ linkedCases[0].customerLastname }}
        {{ linkedCases[0].createdAt }}
      </v-card-title>
      <v-card-subtitle>{{ linkedCases[0].customerMailAddress }} - {{ linkedCases[0].contractNumber }}</v-card-subtitle>
    </div>
    <v-row class="ma-5">
      <v-col cols="12" v-for="linkedCase in linkedCases" v-bind:key="linkedCase.id">
        <v-card color="primary">
          <v-card-title>
            {{ linkedCase.createdBy ? linkedCase.createdBy : "Nicht zugewiesen" }}
          </v-card-title>
          <v-card-subtitle>
            Fallart: {{ linkedCase[dataTypeName] }}
          </v-card-subtitle>
          <v-card-title three-line>
            {{ linkedCase.notes }}
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {sessionHandler} from "@/request/sessionHandler";
import {requestMode} from "@/configBuilder";
import {showAlert} from "@/utils/alertHandler";
import i18n from "@/plugins/i18n";

export default {
  name: "LinkedUUIDCases",
  props: {
    caseUUID: {
      type: String,
      required: true
    },
    controllerURL: {
      required: true
    },
    dataTypeName: {
      required: true
    },
  },
  data: () => ({
    linkedCases: [],
  }),
  methods: {
    async getDocuments() {
      let parameter = {
        'caseUUID': this.caseUUID
      }
      await sessionHandler();
        await this.$http
            .get(
                this.controllerURL
                , {
                  mode: requestMode(),
                  params: parameter,
                  headers: {
                    'Accept': 'application/json',
                    'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                  },
                }
            )
            .then((response) => {
              if (response.status === 200) {
                this.linkedCases = response.data
              }
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.status === 401) {
                  showAlert(i18n.t('warning.unauthorised'), "warning");
                }
              } else {
                showAlert(i18n.t('error.api.undefined') + "Calendar-gE2" + this.admin, "error");
              }
            })
            .finally(() => {
              this.isLoadingBill = false;
            })
    }
  },
  mounted() {
    this.getDocuments()
  }
}
</script>

<style scoped>

</style>
