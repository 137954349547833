<template>
  <v-dialog
      v-model="dialog"
      width="1000"
      v-if="currentCase.customerMailAddress"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn   v-bind="attrs"
               v-on="on" color="primary" icon>
        <v-icon>mdi-mail</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="primary">
        E-Mail Versand
      </v-card-title>
      <v-card-subtitle class="mt-4">Ansprache und Signatur werden automatisch beim senden hinzugefügt.</v-card-subtitle>
      <v-card-subtitle><strong>Betreff:</strong> Nachricht von Ihrer Rechtsanwaltskanzlei ({{currentCase.customerName ? currentCase.customerName : ""}} {{currentCase.claimNumber? currentCase.claimNumber : ""}})</v-card-subtitle>
      <v-card-text >
        <v-textarea v-on:input="saveToLocalStorage()" v-model="closingCustomerMessage" :counter="8000" label="E-Mail Text" outlined></v-textarea>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            v-if="currentCase.closingCustomerMessage && closingCustomerMessage.length === 0"
            color="accent"
            @click="loadLastMessage()"
        >
          Zusetzt gesendete Nachricht einfügen
        </v-btn>
        <v-btn
            color="error"
            @click="dialog = false"
        >
          Abbrechen
        </v-btn>
        <v-btn
            color="primary"
            :disabled="closingCustomerMessage.length > 8000"
            @click="sendMail(currentCase.id)"
        >
          Absenden
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {sessionHandler} from "@/request/sessionHandler";
import {requestMode} from "@/configBuilder";
import {showAlert} from "@/utils/alertHandler";
import i18n from "@/plugins/i18n";

export default {
  name: "CustomerMailDialog",
  props: {
    currentCase: {
      required: true
    },
    controllerURL: {
      required: true
    },
  },
  data: () => ({
    dialog: false,
    closingCustomerMessage: "",
  }),
  methods: {
    async sendMail(openCaseId) {
      await sessionHandler();
      await this.$http
          .patch(
              this.controllerURL + "/" + "Own" + "/" + openCaseId,
              {
                "closingCustomerMessage": this.closingCustomerMessage,
              }
              , {
                mode: requestMode(),
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then((response) => {
            if (response.status === 200) {
              this.dialog = false
              this.clearRecoverData()
              this.$emit("getCases")
              showAlert("Die E-Mail wurde erfolgreich versendet", "success");
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "Calendar-gE2", "error");
            }
          })
          .finally(() => {
            this.dialogMail = false
          })
    },
    loadLastMessage(){
      this.closingCustomerMessage = this.currentCase.closingCustomerMessage
    },
    // Clear localStorage
    clearRecoverData() {
      localStorage.setItem('CustomerMailDialogCase' + this.currentCase.id, "")
    },
    recoverData() {
      this.closingCustomerMessage = localStorage.getItem('CustomerMailDialogCase' + this.currentCase.id)
    },
    saveToLocalStorage(){
      localStorage.setItem('CustomerMailDialogCase' + this.currentCase.id, this.closingCustomerMessage)
    }

  },
  mounted() {
    if (localStorage.getItem('CustomerMailDialogCase' + this.currentCase.id)) {
      this.recoverData()
    }
  }
}
</script>

<style scoped>

</style>
