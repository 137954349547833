<script>
import {sessionHandler} from "@/request/sessionHandler";
import {requestMode} from "@/configBuilder";
import {showAlert} from "@/utils/alertHandler";
import i18n from "@/plugins/i18n";

export default {
  name: "ChevalierDialog",
  components: {},
  props: {
    openCase: {
      required: true
    },
    controllerURL: {
      required: true
    },
    dataType: {
      required: true,
    },
    dataTypeName: {
      required: true
    },
  },
  data: () => ({
    submitReport: "",
    submitDialog: false,
    vermittlungCase: [],
    isLoading: false,
  }),
  mounted() {
  },
  methods: {
    getChevalierPermission() {
      if (this.$store.state.allowedAdvocardServiceTypesForChevalier.includes(this.openCase.serviceType)) {
        return this.$RStore.user.permissions.insurance.advocardPrivateDataType.ChevalierVermittlung
      }
      return false
    },
    addToSubmitReport(text) {
      this.submitReport = this.submitReport + text
    },
    async submitToChev(openCaseId) {
      await sessionHandler();
      await this.$http
          .patch(
              this.controllerURL + "/" + "Own" + "/" + openCaseId + "/TransmitToExternalServiceProvider",
              {
                "notes": this.submitReport,
              }
              , {
                mode: requestMode(),
                params: {
                  "externalServiceProvider": "Chevalier"
                },
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then((response) => {
            if (response.status === 200) {
              showAlert("Der Fall wurde erfolgreich übermittelt", "info");
              this.submitDialog = false;
              this.getCases();
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              }
              if (error.response.status === 400) {
                showAlert("Wurde bereits übermittelt", "warning");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "Calendar-oCC-2", "error");
            }
          })
          .finally(() => {
            this.isLoadingBill = false;
          })
    },
  }
}
</script>

<template>
  <v-dialog
      v-model="submitDialog"
      max-width="600"
      transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          class="ml-2"
          color="primary"
          v-bind="attrs"
          v-on="on"
          v-if="getChevalierPermission()"
      >
        <v-icon
            left
        >
          mdi-send-variant
        </v-icon>
        Fall übermitteln
      </v-btn>
    </template>
    <template v-slot:default="dialog">
      <v-card :loading="isLoading">
        <v-toolbar
            color="primary"
            dark
        >Fall übermitteln?
        </v-toolbar>
        <v-card-text>
          <p>Wollen Sie den Fall wirklich übermitteln?</p>
          <p>Der Fall wird dadurch an die Chevalier übermittelt.</p>
          <v-textarea
              v-model="submitReport"
              :counter="8000"
              color="primary"
              label="Übermittlungsbericht"
              outlined>
          </v-textarea>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
              text
              color="error"
              @click="dialog.value = false"
          >Abbruch
          </v-btn>
          <v-btn v-if="openCase.record" @click="addToSubmitReport(openCase.record)">Sachverhalt einfügen</v-btn>
          <v-btn v-if="openCase.closingCustomerMessage" @click="addToSubmitReport(openCase.closingCustomerMessage)">Kundennachricht einfügen</v-btn>
          <v-btn :disabled="submitReport.length === 0 || submitReport.length > 8000" color="primary" @click="submitToChev(openCase.id)">Fall übermitteln</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<style scoped>

</style>
