<template>
  <v-dialog
      v-model="dialog"
      width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          v-bind="attrs"
               v-on="on" color="secondary">
        <v-icon
            left
        >
          mdi-pencil
        </v-icon>
        Daten bearbeiten
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="primary">
        Daten nachtragen
      </v-card-title>
      <v-card-subtitle class="mt-4">Die fehlenden Daten sind eventuell aus dem Download auszulesen.</v-card-subtitle>
      <v-card-text >
        <v-form
            :lazy-validation="lazy"
            class="form"
            ref="form"
            v-model="valid"
        >
          <v-row>
            <v-col cols="12">
              <v-text-field
                  v-model="email"
                  :label="inputLabels.email"
                  :rules="emailRules"
                  required
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                  v-model="phone"
                  :label="inputLabels.phone"
                  :rules="phoneRules"
                  required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  :label="inputLabels.policyNumber"
                  :rules="policyNumberRules"
                  v-model="contractNumber"
              ></v-text-field>
            </v-col>

          </v-row>
          <v-row>
            <v-col
                cols="12"
                md="6"
            >
              <v-text-field
                  v-model="name"
                  :label="inputLabels.name"
                  :rules="nameRules"
                  required
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                md="6"
            >
              <v-text-field
                  v-model="lastName"
                  :label="inputLabels.lastName"
                  :rules="nameRules"
                  required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>


      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="error"
            @click="dialog = false"
        >
          Abbrechen
        </v-btn>
        <v-btn
            color="primary"
            :disabled="!valid"
            @click="closeDialog()"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {sessionHandler} from "@/request/sessionHandler";
import {
  getAdvocardBusinessURL,
  getAdvocardInternationalURL,
  getAdvocardPrivateURL,
  requestMode
} from "@/configBuilder";
import {showAlert} from "@/utils/alertHandler";
import i18n from "@/plugins/i18n";

export default {
  name: "AddToAdvocard",
  props: {
    advocardData: {
      type: Object,
      required: true
    },
    insuranceType: {
      type: String, // Advocard Private - Advocard International - Advocard Business
      required: true
    },
  },
  data: () => ({
    lazy: false,
    valid: true,
    dialog: false,
    phone: '+49',
    email: '',
    lastName: '',
    name: '',
    contractNumber: '',
  }),
  methods: {
    closeDialog(){
      this.sendData();
    },
    async sendData() {
      // set Loading true
      this.isLoading = true;

      let url = this.insuranceType === "Advocard Private" ? getAdvocardPrivateURL(true) : this.insuranceType === "Advocard International" ? getAdvocardInternationalURL(true) : getAdvocardBusinessURL(true);

      // init Request Data
      const formData = {
        "customerMailAddress": this.email,
        "customerPhoneNumber": this.phone,
        "customerName": this.name,
        "customerLastname": this.lastName,
        "contractNumber": this.contractNumber,
      };
      // console.log("data", formData)
      //  if Session isValid
      await sessionHandler();

      // send add to Database request
      await this.$http
          .patch(
              url  + "/" + this.advocardData.id,
              formData
              , {
                mode: requestMode(),
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then((response) => {
            if (response.status === 200) {
              this.dialog = false
              this.$emit("getCases")
            } else {
              showAlert(i18n.t('error.api.undefined') + "ARAG-sD1", "error");
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              } else {
                showAlert(i18n.t('error.api.undefined') + "AdvocardPrivate-sD2", "error");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "ARAG-sD2", "error");
            }
          })
          .finally(() => {
            this.isLoading = false;
          })
    },
    //Hide policyNumber/claimNumber
    hideNumbers(otherNumber) {
      if (this.free) {
        return false;
      }
      if (otherNumber === '') {
        return true;
      }
    },
  },
  computed: {
    inputLabels() {
      return JSON.parse(JSON.stringify(this.$t('insurance.Arag.inputField')));
    },
    errorMessage() {
      return JSON.parse(JSON.stringify(this.$t('error.validation')));
    },
    emailRules() {
      return [
        v => /^\S+@\S+\.\S+$/.test(v) || this.errorMessage["validEmail"],
        v => !!v || this.errorMessage.required,
      ]
    },
    policyNumberRules() {
      return [
        v => /^\d[.]\d{3}[.]\d{3}[.]\d$/.test(v) || this.errorMessage["policyNumberError"],
        v => !!v || this.errorMessage.required,
      ]
    },
    phoneRules() {
      return [
        v => /\+[0-9]{2}[1-9][0-9]{1,25}/.test(v) || this.errorMessage["validPhone"],
        v => !!v || this.errorMessage.required,
      ]
    },
    nameRules() {
      return [
        v => !!v || this.errorMessage.required,
      ]
    },
  },
  mounted() {
    if(this.advocardData.customerPhoneNumber){
      this.phone = this.advocardData.customerPhoneNumber.length > 0 ? this.advocardData.customerPhoneNumber : "+49" ;
    }
    this.email = this.advocardData.customerMailAddress ? this.advocardData.customerMailAddress : "";
    this.name = this.advocardData.customerName ?  this.advocardData.customerName: "";
    this.lastName = this.advocardData.customerLastname ?  this.advocardData.customerLastname: "";
    this.contractNumber = this.advocardData.contractNumber ?this.advocardData.contractNumber : "";
  },
}
</script>

<style scoped>

</style>
